<template>
    <div class="">

        <!-- error summary starts -->
        <error-summary :errors="errors"></error-summary>
        <!-- error summary ends -->



        <form id="passwordResetForm"  class="form--full-width form--transparent-fieldsets"
              @submit.prevent="sendVerificationCode"
              action="" method="">
            <div class="row">
                <div class="column small-12 medium-7 end">

                    <label for="username"
                           class="form__label"
                           v-bind:class="{ 'form__label--error': errors.hasFieldError('username') }">
                        UQ username <span class="form__required">(required)</span>
                    </label>
                    <input type="text"
                           name="username"
                           id="username"
                           class="form__input"
                           v-model="params.username"
                           v-on:keydown="resetErrors()"
                           v-bind:class="{ 'form__input--error': errors.hasFieldError('username') }"
                           v-bind:aria-invalid="errors.hasFieldError('username')"
                    />
                    <div id="usernameError" aria-live="polite"
                         v-if="errors.hasFieldError('username') && errors.getFieldErrorMessages('username').length > 0"
                         v-bind:class="{ 'form__inline-error-message': errors.hasFieldError('username') }"
                    >
                        <div v-bind:key="message"
                             v-for="message in errors.getFieldErrorMessages('username')">
                            {{ message }}
                        </div>
                    </div>
                    <p id="usernameDesc" class="form__help-text"><a href="https://support.my.uq.edu.au/app/answers/detail/a_id/2535" target="_blank">Forgotten your username?</a></p>
                    <fieldset>
                        <legend id="contactKindDesc" class="form__legend">We will send you a verification code so you can regain access to your account.<br/>Where should we send your verification code? <span class="form__required">(required)</span></legend>
                        <div>
                            <label id="emailRadioLabel" class="form__label">

                                <input type="radio"
                                       name="contactKind"
                                       id="contactKindEmail"
                                       value="Email"
                                       title="Send verification code to personal email address"
                                       v-model="params.contactKind"
                                       v-on:click="resetErrors()"
                                       v-bind:class="{ 'form__input--error': errors.hasFieldError('contactKind') }"
                                       v-bind:aria-invalid="errors.hasFieldError('contactKind')"
                                />

                                Personal email
                            </label>


                            <label for="email"
                                   class="form__label form__label--disabled"
                                   v-bind:class="{ 'form__label--error': errors.hasFieldError('email') }">
                                The personal email address you had set in my.UQ dashboard
                            </label>
                            <input type="email"
                                   name="email"
                                   id="email"
                                   class="form__input"
                                   v-model="params.email"
                                   :disabled="this.params.contactKind !== 'Email'"
                                   aria-describedby="emailError registeredDetailsDesc"
                                   v-on:keydown="resetErrors()"
                                   v-bind:class="{ 'form__input--error': errors.hasFieldError('email') }"
                                   v-bind:aria-invalid="errors.hasFieldError('email')"
                            />
                            <div id="emailError" aria-live="polite"
                                 v-if="errors.hasFieldError('email') && errors.getFieldErrorMessages('email').length > 0"
                                 v-bind:class="{ 'form__inline-error-message': errors.hasFieldError('email') }"
                            >
                                <div v-bind:key="message"
                                     v-for="message in errors.getFieldErrorMessages('email')">
                                    {{ message }}
                                </div>
                            </div>

                        </div>
                        <div>
                            <label id="phoneRadioLabel" class="form__label">
                                <input type="radio"
                                       name="contactKind"
                                       id="contactKindMobile"
                                       value="Phone"
                                       title="Send verification code to mobile number"
                                       aria-describedby="contactKindError contactKindDesc"
                                       v-model="params.contactKind"
                                       v-on:click="resetErrors()"
                                       v-bind:class="{ 'form__input--error': errors.hasFieldError('contactKind') }"
                                       v-bind:aria-invalid="errors.hasFieldError('contactKind')"
                                />


                                Mobile number
                            </label>


                            <label for="phone"
                                   class="form__label form__label--disabled"
                                   v-bind:class="{ 'form__label--error': errors.hasFieldError('phone') }">
                                The mobile number you had set in my.UQ dashboard
                            </label>
                            <input type="text"
                                   name="phone"
                                   id="phone"
                                   ref="phone"
                                   class="form__input"
                                   v-model="params.phone"
                                   :disabled="this.params.contactKind !== 'Phone'"
                                   aria-describedby="phoneError registeredDetailsDesc"
                                   v-on:keydown="resetErrors()"
                                   v-bind:class="{ 'form__input--error': errors.hasFieldError('phone') }"
                                   v-bind:aria-invalid="errors.hasFieldError('phone')"
                            />
                            <div id="phoneError" aria-live="polite"
                                 v-if="errors.hasFieldError('phone') && errors.getFieldErrorMessages('phone').length > 0"
                                 v-bind:class="{ 'form__inline-error-message': errors.hasFieldError('phone') }"
                            >
                                <div v-bind:key="message"
                                     v-for="message in errors.getFieldErrorMessages('phone')">
                                    {{ message }}
                                </div>
                            </div>

                        </div>
                        <div id="contactKindError" aria-live="polite"
                             v-if="errors.hasFieldError('contactKind') && errors.getFieldErrorMessages('contactKind').length > 0"
                             v-bind:class="{ 'form__inline-error-message': errors.hasFieldError('contactKind') }"
                        >
                            <div v-bind:key="message"
                                 v-for="message in errors.getFieldErrorMessages('contactKind')">
                                {{ message }}
                            </div>
                        </div>

                    </fieldset>
                    <br/>
                    <button type="submit" value="Submit">Continue</button>
                    <button type="reset" class="button--white" value="Start over" id="resetStartOver" onclick="document.location.href='/password-reset'">Start over</button>
                </div>
            </div>
        </form>

    </div>
</template>
<script src="/javascript/Rpc.js"></script>
<script src="/javascript/script.js"></script>
<script>
    import ErrorSummary
        from "../../Shared/VueComponents/ErrorSummary";
    import intlTelInput from 'intl-tel-input';
    import 'intl-tel-input/build/css/intlTelInput.css';

    export default {
        name: "PersonalDetail",
        components: {
            ErrorSummary,
            intlTelInput
        },

        data() {
            return {
                params: {
                    contactKind: '',
                    username: '',
                    phone: '',
                    email: '',
                },

                errors: new StructuredError({}),

                intlTelInput            : null,
            };
        },
        props:{
            token:{
                type: String,
                required: true,
            },

            assetPrefix:{
                type: String,
                required: true,
            }
        },
        mounted () {
            if ( ! this.intlTelInput )
            {
                let $input = this.$refs.phone;
                if ( $input )
                {
                    this.intlTelInput                   = intlTelInput($input, {
                        excludeCountries: ['ru', 'kp'],
                        // allowDropdown: false
                        initialCountry: 'au'
                    });
                }
            }
        },

        methods:{
            sendVerificationCode: function(e){

                this.validate();

                if ( this.errors.data.length > 0 )
                {
                    return false;
                }

                let params                  = this.params;
                params.contactValue         = params.email;
                if ( this.params.contactKind === 'Phone' )
                {
                    params.contactValue         = this.intlTelInput.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
                }

                params.token                = this.token;

                let rpc                     = new RpcRequest('PasswordReset\\RpcSendContactVerification', params);
                let that                    = this;
                rpc.send(function(res) {

                    window.location.href = res.result.redirect;

                }, function(res) {

                    that.errors = res.getErrors();

                });
            },
            validate() {

                this.errors                     = new StructuredError({});
                let data                        = [];
                if ( ! this.params.username.length > 0 )
                {
                    data.push(new FieldError('username', ['Username cannot be empty']));
                }
                if ( ! this.params.contactKind.length > 0 )
                {
                    data.push(new FieldError('contactKind', ['You have to select either Personal email or Mobile number']));
                }
                if ( this.params.contactKind === 'Email' && ! this.params.email > 0  )
                {
                    data.push(new FieldError('email', ['Personal email cannot be empty']));
                }
                if ( this.params.contactKind === 'Phone' )
                {
                    if ( ! this.params.phone > 0 )
                    {
                        data.push(new FieldError('phone', ['Mobile number cannot be empty']));
                    }
                    else if ( ! this.intlTelInput.isValidNumber() )
                    {
                        data.push(new FieldError('phone', ['Please enter a valid mobile number']));
                    }

                }

                if ( data.length > 0)
                {
                    this.errors                     = new StructuredError({data: data, message: ""})
                }
            },

            resetErrors(){
                this.errors                     = new StructuredError({});
            }
        },
    }
</script>

<style lang="css">
.iti{
    display: block !important;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>


<style>
    @import '/asset/20200624/css/uq_standard.css';
    @import '/asset/20200624/css/identity-portal.css';
    @import '../../../node_modules/intl-tel-input/build/css/intlTelInput.css';
</style>

<!--

node_modules/@vue/cli-service/bin/vue-cli-service.js build --target wc Pages/PasswordReset/PersonalDetailPage/PersonalDetail.vue --dest ./public/Pages/PasswordReset/PersonalDetailPage/PersonalDetail

-->