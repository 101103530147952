<template>

<div class="row" v-if="errors.getMessage() && errors.getTitle()">
    <div class="column small-12 medium-12 end">
        <div id="errorSummary" class="error-summary" role="alert">
            <!-- error summary contents will be populated here -->
            <h2 class="error-summary__title" >{{ errors.getTitle() }}</h2>
            <div class="error-summary__body">
                {{ errors.getMessage() }}
            </div>
        </div>
    </div>
</div>

</template>

<script src="/javascript/Rpc.js"></script>
<script>
export default {

    name: 'ErrorSummary',
    props:{
        errors:{
            type:  Object,
            default: new StructuredError({}),
        },
    },
    methods:{
        resetErrors(){
            this.errors     = new StructuredError({});
        }
    },
}

</script>
<style type="text/css">
@import '/asset/20200624/css/uq_standard.css';
@import '/asset/20200624/css/identity-portal.css';
</style>