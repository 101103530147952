// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--6-oneOf-1-3!../../../node_modules/intl-tel-input/build/css/intlTelInput.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(/asset/20200624/css/uq_standard.css);"]);
exports.push([module.id, "@import url(/asset/20200624/css/identity-portal.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "", ""]);
// Exports
module.exports = exports;
